import axios from 'axios'
import NProgress from 'nprogress';
import {createToaster} from "@meforma/vue-toaster";

const toaster = createToaster();

axios.defaults.baseURL = process.env.VUE_APP_BASE_BACKEND_URL + "/api/"
// let lang = localStorage.getItem('local') || "ar";
// axios.defaults.baseURL = process.env.VUE_APP_BASE_BACKEND_URL + "/api/" + lang + "/"
axios.interceptors.request.use(function (config) {
        config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
        NProgress.start()
        return config;
    },
    function (error) {
        return Promise.reject(error);
    });


axios.interceptors.response.use(function (response) {
        if (response.config.method == 'post' && response.status == 200) {
            toaster.success('Success', {position: 'top'});
        }
        NProgress.done()
        return response;
    },
    function (error) {
        NProgress.done()
        // console.log(error.config)
        const showError = IsJsonString(error.config.data) ? JSON.parse(error.config.data) : {};
        if (showError?.withoutMessage) {
            return Promise.reject(error);
        }
        const errors = error.response?.data?.errors;
        const errorAsMessage = error.response?.data?.message;
        const isLoggedIn = localStorage.getItem('user') != undefined;
        if (errors && !errorAsMessage) {
            Object.values(errors).forEach(item => {
                toaster.error(item, {position: 'top'})
            })
        }

        if (error.response.status !== 200 && errorAsMessage || (errors && typeof errors == 'string' && isLoggedIn)) {
            toaster.error(errorAsMessage, {position: 'top'});
        }

        return Promise.reject(error);
    });

function IsJsonString(str) {
    try {
        var json = JSON.parse(str);
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}

export default axios
