import {createStore} from 'vuex'
import apis from "@/service/apis";
import axios from "@/service/axios";

export default createStore({
    state: {
        user: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
        local: localStorage.getItem('local') || 'ar',
        faqs: [],
        blogItem: {},
        checkerInfo: {},
        content: {},
        setting: localStorage.getItem('setting') != null ? JSON.parse(localStorage.getItem('setting')) : null
    },
    getters: {
        user: (state) => {
            return typeof state.user == 'string' ? JSON.parse(state.user) : typeof state.user == 'object' ? state.user : null
        },
        setting: (state) => {
            return typeof state.setting == 'string' ? JSON.parse(state.setting) : typeof state.setting == 'object' ? state.setting : null
        },
        project: (state) => {
            return state.project
        },
        orderSummary: (state) => {
            return state.orderSummary
        },
        local: (state) => {
            return state.local
        },
        isCheckerUser: (state) => {
            return state.user != undefined && state.user != null && state.user?.type != undefined && state.user?.type != 'user';
        },
        getFAQs(state) {
            return state.faqs;
        },
        checkerInfo(state) {
            return state.checkerInfo;
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        SET_SETTING(state, data) {
            state.setting = data
            localStorage.setItem('setting', JSON.stringify(data))
        },
        SET_NEW_PROJECT(state, data) {
            state.project = {...state.project, ...data}
            localStorage.setItem('project', JSON.stringify(state.project))
        },
        SET_BLOG_ITEM(state, data) {
            state.blogItem = data
        },
        EMPTY_PROJECTS(state) {
            state.project = {}
        },
        SET_CONTENT(state, data) {
            state.content = data
        },

        EMPTY_LOCAL(state, local) {
            localStorage.setItem('local', local)
            if (state.local !== local) {
                window.location.reload();
            }
        },
        SET_ORDER_SUMMARY(state, data) {
            state.orderSummary = data;
            localStorage.setItem('orderSummary', JSON.stringify(state.orderSummary))

        },
        SET_FAQS(state, data) {
            state.faqs = data
        },
        SET_CHECKER_INFO(state, data) {
            state.checkerInfo = data
        }
    },
    actions: {
        user(context, user) {
            context.commit('SET_USER', user)
        },
        setting(context, data) {
            context.commit('SET_SETTING', data)
        },
        updateLocal(context, local) {
            context.commit('EMPTY_LOCAL', local)
        },
        updateProject(context, data) {
            context.commit('SET_NEW_PROJECT', data)
        },
        async downloadFile(context, id) {
            return await axios.get(apis.downloadFile + '/' + id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                'responseType': 'blob'
            }).then((res) => {
                return res.data;
            }).catch(() => {
                return Promise.reject(false);
            })
        },
        async downloadPriceOffer(context, id) {
            return await axios.get(apis.pricing + '/' + id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                'responseType': 'blob'
            }).then((res) => {
                return res.data;
            }).catch(() => {
                return Promise.reject(false);
            })
        },
        emptyProjects(context) {
            context.commit('EMPTY_PROJECTS')
        },
        updateOrderSummary(context, data) {
            context.commit('SET_ORDER_SUMMARY', data)
        },
        async saveProject({commit, state}) {
            state.project.project_id = state.project?.id
            return await axios.post(apis.projects, state.project).then(res => {
                commit('SET_ORDER_SUMMARY', res.data.data);
                commit('SET_NEW_PROJECT', res.data.data.project)
                return true;
            }).catch(() => {
                return Promise.reject(false);
            })
        },

        async sendContactForm(context, data) {
            return await axios.post(apis.contact, data).then(() => {
                return true;
            }).catch(() => {
                return Promise.reject(false);
            })
        },
        async getProject({commit}, id) {
            return await axios.get(apis.projects + '/' + id).then(res => {
                commit('SET_NEW_PROJECT', res.data.data.project)
                commit('SET_CHECKER_INFO', res.data.data.checkerinfo)
                commit('SET_ORDER_SUMMARY', res.data.data);
                return true;
            }).catch(() => {
                return Promise.reject(false);
            })
        },

        async getFAQs({commit}) {
            return await axios.get(apis.faqs).then(res => {
                commit('SET_FAQS', res.data.data);
                return Object.values(res.data.data);
            }).catch(() => {
                return Promise.reject(false);
            })
        },
        async updateBlogItem({commit}, data) {
            commit('SET_BLOG_ITEM', data)
        },
        async completeProject({commit, getters}) {
            return await axios.post(apis.completeProject, {project_id: getters.project.id}).then(() => {
                commit('SET_NEW_PROJECT', {});
                return true;
            }).catch(() => {
                return false;
            })
        },
        async saveProjectOwnershipProperty(context, data) {
            return await axios.post(apis.ownershipPropertyStore, data).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        },
        async getContent(context) {
            return await axios.get(apis.content).then((res) => {
                context.commit('SET_CONTENT', res.data.data)
            }).catch(() => {
                return false;
            })
        },
        async getSetting(context) {
            return await axios.get(apis.setting).then((res) => {
                context.commit('SET_SETTING', res.data.data)
            }).catch(() => {
                return false;
            })
        },
        async closeForm(context, projectId) {
            return await axios.post(apis.ownershipPropertyFormClose, {project_id: projectId}).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        },
        async getInfrastructure() {
            return await axios.get(apis.Infrastructure).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async getFeature() {
            return await axios.get(apis.Feature).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async getNotes() {
            return await axios.get(apis.getNotes).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async addImage(context, data) {
            return await axios.post(apis.addImage, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: {withoutMessage: true}
            }).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async addDetails(context, data) {
            return await axios.post(apis.addDetails, data, {data: {withoutMessage: true}}).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async addInfrastructure(context, data) {
            return await axios.post(apis.addInfrastructure, data, {data: {withoutMessage: true}}).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },

        async addFeature(context, data) {
            return await axios.post(apis.addFeature, data).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async saveNotes(context, data) {
            return await axios.post(apis.saveNotes, data).then((res) => {
                return res.data.data
            }).catch(() => {
                return false;
            })
        },
        async addProjectDocs(context, data) {
            return await axios.post(apis.addProjectDocs, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        },

        async sendPropertyData(context, data) {
            return await axios.post(apis.ownershipPropertyFormStore, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        },
        async deleteImageFromServer(context, data) {
            return await axios.post(apis.deleteImageFromServer, data).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        },

    },
    modules: {}
})
