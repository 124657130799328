import {createI18n} from "vue-i18n";
import store from "@/store";
import ar from "@/lang/ar";
import en from "@/lang/en";

const i18n = createI18n({
    // locale: store.getters.local,
    locale: localStorage.getItem('local') || 'ar', // Default locale
    fallbackLocale: "ar",
    messages: {ar, en}
})

var currentLocale = store.getters.local || 'ar';
i18n.global.locale = currentLocale;
export default i18n;
