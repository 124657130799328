import {createApp} from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n';
import './service/axios'
import BootstrapVue3 from 'bootstrap-vue-3'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import loading from './directives/loading'
import '../node_modules/nprogress/nprogress.css'


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from './service/axios'
import VueAxios from 'vue-axios'
import ImagesMixin from "@/mixins/ImagesMixin";
import LocalResolver from "@/mixins/LocalResolver";
import Toaster from '@meforma/vue-toaster';
import moment from "moment";

require('bootstrap')
require('axios')

const locale = i18n.global.locale

async function loadStylesheet(locale) {
    if (locale === 'ar') {
        document.querySelector('html').dir = 'rtl'
        await import('./assets/css/main.css')
    } else {
        document.querySelector('html').dir = 'ltr'
        await import('./assets/css/main-ltr.css')
    }
}

loadStylesheet(locale)

const app = createApp(App)
    .use(i18n);
app.use(BootstrapVue3);
app.use(Toaster, {
    position: "top",
});

app.config.globalProperties.$local = store.getters.local || 'ar';
i18n.global.locale = store.state.local;

// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker);

app.directive('loading', loading);
app.mixin(ImagesMixin)
app.mixin(LocalResolver)
app.config.globalProperties.$filters = {
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY hh:mm')
        }
    }
}

app.use(store).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDfRqStj1PM2aUx3MsmhsBAWxVutO02sMI',
    },
}).use(router).use(VueAxios, axios).mount('#app')
