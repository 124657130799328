<script>
import i18n  from "@/i18n";
export default {
  name: "LocalResolver",
  methods: {
    resolveByLocal: function (element, prop) {
      if (!element) return  '';
      if (i18n.global.locale == 'ar') {
        return element[`${prop}_ar`];
      }
      return element[`${prop}_en`];
    }
  }
}
</script>