export default {
    ar: "العربية",
    en: "English",
    qatar: "قطر",
    statuses: {
        undefined: "Incomplete",
        Unfinished: "Incomplete",
        Pending: "Pending",
        Accepted: "Accepted",
        Checking: "Pending",
        Chacking: "Pending",
        EndCheck: "Pending",
        Auditing: "Pending",
        Done: "Completed",
    },
    form: {
        level1: "General Structure",
        level2: "Properties",
        level3: "Subtle Details",
    },
    click_to_see_more: "Click to view more",
    create_new_project: "Create a new project",
    customer_data: "Beneficiary Data",
    user_info: "User Information",
    structure_unit_data: "Details of the property unit",
    checking_data: "Inspection Details",
    order_summary: "Order Summary",
    pay_cache: "Will you pay in cash (you will be contacted by the examiner and the project status will be pending)",
    next: "Next",
    add_new: "Add new",
    update_project: "Edit project",
    yes: "I agree",
    no: "Disagree",
    payment: "Payment",
    cash: "Cash",
    prev: "Previous",
    email_sent: "Password recovery email sent to the email you entered successfully",
    count: "Number",
    readmore: "Read more",
    add_other: "Add another",
    home: "Home",
    project_form: "Project Form",
    appearance: "Appearance",
    close_form: "Close Form",
    performance: "Performance",
    images: "Images",
    project_document: "Project Documents",
    close: "Close",
    send_docs: "Save files",
    filename: "File Name",
    sent_success: "Submitted successfully",
    property_project_completed_successfully: "Information saved successfully",
    add_values: "Add information",
    already_project_form_submitted: "Project data has already been entered",
    add_more: "Add more",
    checker_dashboard: "Dashboard",
    dont_have_permission: "You do not have permission to access this page",
    name: "Name",
    back: "Back",
    report: "Report",
    rate: "Rating",
    zone_no: "Zone number",
    street_number: "Street number",
    payment_info: "Payment information",
    order_number: "Order number",
    check_data: "Inspection time",
    checker_name: "Name of the examining engineer",
    project_status: "Project status",
    rate_service: "Service rating",
    order_details: "Order details",
    service_type: "Type service",
    the_message: "Message",
    check_date: "Inspection Date",
    basic_ayan: "Basic Mawthuq",
    preferred_date: "Preferred Date",
    preferred_time: "Preferred Time",
    preferred_time_hour: "A convenient time for you to inspect",
    preferred_time_minutes: "minutes",
    confirm: "Confirm",
    period: "Period",
    hello: "Hello",
    choose_custom_checker: "Choose a specific inspector",
    create_new_order: "Create a new order",
    orders: "Orders",
    notes: "Notes",
    building_no: "Building Number",
    unit_no: "Unit Number",
    title: "Address",
    choose_answer: "Choose an Answer",
    building_area: "Roof Area (Sqm)",
    open_date: "Open Time",
    building_type: "Property Type",
    building_details: "Property Details",
    other_select: "Other Selection",
    street_no: "Street Number",
    send: "Send",
    my_profile: "My Account",
    not_exist: "Not Existing",
    project_completed_successfully:
        "Project saved successfully please check email",
    my_projects: "My Projects",
    total: "Total Total",
    vat: "Value Added Tax",
    total_cost: "Total Request",
    currency: "Qatari Riyal",
    unit: "m2",
    check_date_and_period: "Date and Period",
    services: "Services",
    subject: "Subject",
    forget_password: "Forgot your password?",
    reset_password: "Forgot your password?",
    reset_password_body: "Don't worry! Send us your email and a password reset link will be sent immediately",
    register_new: "Register a new account",
    first_name: "First Name",
    last_name: "Last Name",
    password: "Password",
    phone: "Phone Number",
    full_name: "Name",
    phone_number: "Phone Number",
    email: "Email",
    site_type: "Property Type",
    location: "Location",
    explore_website: "Browse the site",
    about: "About Mawthuq",
    price_offer: "Price Offer",
    tslam: "Looking to buy a home of a lifetime? #Mawthuq_Received",
    calculate_check_cost: "Calculate the cost of the inspection",
    common_question: "Frequently Asked Questions",
    blog: "Blog",
    contact: "Contact",
    main_check_items: "Main inspection items",
    homeReviewsTitle: "Our customers' opinions",
    eleictric: "Electricity",
    eleictric_text: "Checking plugs, lighting units, and electrical panels.",
    plumbing: "Plumbing",
    plumning_text: `
Checking floor slopes, sanitary ware, water filters, visible plumbing pipes, water heater, water tank, water pump.
`, walls: "Walls",
    walls_text: `Check paints, leakage traces, cracks and fissures, wall ceramics, cornice
walls.`,
    doors: "Doors",
    doors_text: `
Check the quality of each door, rust and wear, frame quality, smoothness of opening and closing,
handles, hinges, locks, bumpers, door weight.
`,

    windows: "Al-Nawaf",

    windows_text: `Check the quality of glass, frame, insulation, smooth opening and closing, channel, locks, bars

Protection, insect netting.`,

    eng: "Engineer",

    project_details: "Project Details",

    project_image: "Project Image",

    "building_status": "Construction Status",

    "entry_number": "Entry Number",

    "contract_number": "Construction Manager",

    "owner": "Owner",

    "artitacture_developer": "Architect",

    "office_design": "Design Office",

    "supervisor_eng": "Supervising Engineer",

    "main_provider": "Main Owner",

    "electri_counter1": "Electricity Meter 1",

    "electri_counter2": "Electricity Meters 2",

    "electri_counter_reed1": "Electricity Meter Reading 1",
    "electri_counter_reed2": "Electricity meter reading 2",
    "water_counter": "Water meter",
    "water_counter_reed": "Water meter reading",
    "property_age": "Building age",
    "ground_space": "Floor area",
    "building_spaces": "Building area",
    "floors": "Number of floors",
    project_infrastructure: 'Infrastructure',
    project_feature: 'Features',
    project_notes: 'Highlights',
    grands: 'Floors',
    proierty: 'Importance',
    note: 'Notes',
    recomend: 'Recommendations',
    grands_text: `Inspection of floor finish, drums, grouting, soles, threshold, expansion joints`,
    roofs: "Roofs",
    no_orders: "No orders",
    roofs_text: "Inspection of paints, decorations, cracks And cracks, maintenance openings.",
    conditioning: "Air conditioning and ventilation",
    conditioning_text: "Examination of exhaust fans, air conditioning units, air conditioning installations and air conditioning openings.",
    register_as_checker: "New registration as an inspector engineer",
    register_as_checker_note: "This page is for engineers who wish to join the Mawthuq technical team",
    login: "Log in",
    no_data: "No data",
    request_service: "Request a service",
    what_we_offer: "What do we offer at Mawthuq?",
    what_we_offer_text: `
At Mawthuq, we seek to simplify the process of inspecting real estate

To help the buyer make a decision based on clear information before purchasing.
`,

    contact_and_question: "For communication and inquiries",

    payment_methods: "Secure payment methods",

    policy_1: "All rights reserved to Mawthuq platform © 2019-2021",

    policy_2: "Terms of Use",

    policy_3: "Privacy Policy",

    policy_4: "Cancellation and Refund Policy",

    vision: "Our Vision",

    message: "Our Message",

    follow_us: "Follow Us",

    my_account: "My Account",

    register_user: "User Registration",

    register_check: "Checker Registration",

    register: "New Account Registration",

    logut: "Log Out",

    request_new_form: "Request to fill out a form for this project",

    newest_articles: "Latest Articles",

    register_email: `Please enter your email, and a message will be sent to verify the email.`,
    footer: {
        whatsapp: "WhatsApp",

        email: "email",
    },
    about_texts: {
        text1: "Ayen is an electronic platform that enables you to easily request a real estate inspection service and obtain a professional inspection report with easy-to-read indicators by qualified inspectors. Ayen was founded in September 2017 and began its journey as an inspection service provider. However, due to the increasing demand, the founders decided to transform it into a platform that provides inspection services through independent inspectors and specialized inspection offices with experience and efficiency, in August 2018.",
        text3: "Ayen platform is fully owned by Ayen Platform Limited, a Saudi company with commercial registration number 2051226297 and is headquartered in Dammam, Kingdom of Saudi Arabia.",
        text4: "Ayen seeks to simplify the real estate inspection process to help the buyer make a decision based on clear information before purchasing. To achieve this, we designed a matrix of specialized inspection criteria within models derived from the best global practices in this field, then combined the power of knowledge with the ease of technology to produce an easy-to-use and highly efficient service.",
        text5: "To make all real estate transactions transparent and reliable. “To help our clients make informed real estate decisions by enabling professionals to collect, process and visualize data using innovative technologies.”"
    },
}