import {createRouter, createWebHistory} from 'vue-router'
import i18n from '@/i18n';
import HomeView from '../views/site/HomeView.vue'
import LoginView from '../views/site/LoginView.vue'
import FAQView from '../views/site/FAQView.vue'
import PhoneRegiseterView from '../views/site/PhoneRegisterView.vue'
import EmailRegisterView from '../views/site/EmailRegisterView.vue'
import ContactView from '../views/site/ContactView.vue'
import AboutView from '../views/site/AboutView.vue'
import CheckerRegister from '../views/checker/CheckerRegisterView.vue'
import CreateProject from '../views/user/CreateProjectView.vue'
import BlogItem from '../views/site/BlogItem.vue'
import BlogsPage from '../views/site/BlogsPage.vue'
import PrivacyPolicy from '../views/site/PrivacyPolicy.vue'
import TermsConditions from '../views/site/TermsConditions.vue'
import ReturnsPolicy from '../views/site/ReturnsPolicy.vue'

import CheckerDashboard from '../views/checker/CheckerDashboardView.vue'
import Projects from '../views/user/ProjectsView.vue'
import CreateProjectView from "@/views/user/CreateProjectView";
import store from '@/store';
import ServicesView from "@/views/user/ServicesView";
import ProjectDetails from "@/views/user/ProjectDetails";
import ProjectPreview from "@/views/user/ProjectPreview";
import UnAuthComponent from "@/views/site/UnAuthComponent";
import checkerProjectDetails from "@/views/checker/CheckerProjectDetails";
import CheckerProjectFrom from "@/views/checker/CheckerProjectFrom";
import UpdateProjectView from "@/views/user/UpdateProjectView";
import MyProfile from "@/views/site/MyProfile";
import ResetPasswordView from "@/views/site/ResetPasswordView";

const routes = [

    {path: '/:locale(en)?/', name: 'home', component: HomeView},
    {path: '/:locale(en)?/login', name: 'login', component: LoginView},
    {path: '/:locale(en)?/password-reset', name: 'resetPassword', component: ResetPasswordView},
    {path: '/:locale(en)?/faq', name: 'faq', component: FAQView},
    {path: '/:locale(en)?/regph', name: 'regph', component: PhoneRegiseterView},
    {path: '/:locale(en)?/register', name: 'register', component: EmailRegisterView},
    {path: '/:locale(en)?/contact', name: 'contact', component: ContactView},
    {path: '/:locale(en)?/about', name: 'about', component: AboutView},
    {
        path: '/:locale(en)?/services',
        name: 'services',
        component: ServicesView,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {path: '/:locale(en)?/register-as-checker', name: 'register-as-checker', component: CheckerRegister},
    {
        path: '/:locale(en)?/new-project',
        name: 'new-project',
        component: CreateProject,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/:locale(en)?/update-project/:id',
        name: 'updateProject',
        component: UpdateProjectView,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/:locale(en)?/checker-dashboard',
        name: 'checkerDashboard',
        component: CheckerDashboard,
        meta: {requiresAuth: true, onlyCheckers: true}
    },
    {
        path: '/:locale(en)?/checker-project-details/:id',
        name: 'checkerProjectDetails',
        component: checkerProjectDetails,
        meta: {requiresAuth: true, onlyCheckers: true}
    },
    {
        path: '/:locale(en)?/checker-project-form/:id',
        name: 'checkerProjectFrom',
        component: CheckerProjectFrom,
        meta: {requiresAuth: true, onlyCheckers: true}
    },
    {path: '/:locale(en)?/projects', name: 'projects', component: Projects, meta: {requiresAuth: true, onlyUsers: true}},
    {path: '/:locale(en)?/un-auth', name: 'unAuthorize', component: UnAuthComponent},
    {
        path: '/:locale(en)?/new-project',
        name: 'newProject',
        component: CreateProjectView,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/:locale(en)?/project-details/:id',
        name: 'projectDetails',
        component: ProjectDetails,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {
        path: '/:locale(en)?/project-preview/:id',
        name: 'projectPreview',
        component: ProjectPreview,
        meta: {requiresAuth: true, onlyUsers: true}
    },
    {path: '/:locale(en)?/blog/:id', name: 'blogItem', component: BlogItem,},
    {path: '/:locale(en)?/blogs', name: 'blogsPage', component: BlogsPage,},
    {path: '/:locale(en)?/privacy-policy', name: 'privacyPolicy', component: PrivacyPolicy,},
    {path: '/:locale(en)?/terms-conditions', name: 'termsConditions', component: TermsConditions,},
    {path: '/:locale(en)?/returns-policy', name: 'returnsPolicy', component: ReturnsPolicy,},
    {path: '/:locale(en)?/my-profile', name: 'myProfile', component: MyProfile, meta: {requiresAuth: true}},
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'activeLink'
});


router.beforeEach((to, from, next) => {
    let currentLocale = to.params.locale  || "ar";
    i18n.global.locale = currentLocale;

    if  (currentLocale == 'ar' && to.params.local =='en'){
        const newPath = to.fullPath.replace(/^\/en/, '');
        return next({path: newPath, replace: true});
    }
    // if (local === 'ar' && to.fullPath.startsWith('/en')) {
    //     const newPath = to.fullPath.replace(/^\/en/, '');
    //     return next({path: newPath, replace: true});
    // }
    //
    if (currentLocale === 'ar' && to.fullPath.startsWith('/ar')) {
        const newPath = to.fullPath.replace(/^\/ar/, '');
        return next({path: newPath, replace: true});
    }
    //
    // if (local === 'en' && !to.fullPath.startsWith('/en')) {
    //     return next({path: `/en${to.fullPath}`, replace: true});
    // }

    if (to.meta?.requiresAuth && !store.getters.user) {
        return next({name: 'login'});
    }

    if (store.getters.isCheckerUser && to.meta?.onlyUsers) {
        return next({name: 'unAuthorize'});
    }

    if (!store.getters.isCheckerUser && to.meta?.onlyCheckers) {
        return next({name: 'unAuthorize'});
    }
    next();
})

export default router
