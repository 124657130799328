<template>
  <div class="header-container">
    <div id="top-header" class="mh_nav_text_color_custom">
      <div class="container clearfix">
        <div id="mh-info" v-if="setting.find(item => item.key == 'whatsapp')?.value">

          <span class="mh-info-item mh-info-phone mh-icon-before mh-icon-after"><a
              :href="'https://wa.me/' + setting.find(item => item.key == 'whatsapp')?.value" target="_blank">
              {{ setting.find(item => item.key == 'whatsapp')?.value }}</a></span>
        </div>
        <ul class="mh-social-icons">
          <li class="mh-social-icon mh-social-twitter"
              v-if="setting.find(item => item.key == 'twitter')?.value || false">
            <a target="_blank" :href="setting.find(item => item.key == 'twitter')?.value" class="icon">
              <span>Twitter</span>
            </a>
          </li>
          <li class="mh-social-icon mh-social-facebook"
              v-if="setting.find(item => item.key == 'facebook')?.value || false">
            <a target="_blank" :href="setting.find(item => item.key == 'facebook')?.value" class="icon">
              <span>Facebook</span>
            </a>
          </li>
          <li class="mh-social-icon mh-social-instagram"
              v-if="setting.find(item => item.key == 'instagram')?.value || false">
            <a target="_blank" :href="setting.find(item => item.key == 'instagram')?.value"
               class="icon"><span>Instagram</span>
            </a>
          </li>
          <li class="mh-social-icon mh-social-linkedin"
              v-if="setting.find(item => item.key == 'linkedin')?.value || false">
            <a target="_blank" :href="setting.find(item => item.key == 'linkedin')?.value"
               class="icon"><span>linkedin</span>
            </a>
          </li>
        </ul>
        <div id="mh-secondary-menu"></div>
      </div>
    </div>
    <div id="main-header" class="mh_nav_text_color_dark mh_subnav_text_color_dark">
      <div class="container d-flex justify-content-between align-items-center">
        <router-link :to="localizedRoute('home')" class="mh_logo"><img class=" logo-img" alt="موثوق"
                                                              src="../../assets/img/logo.png"/>
          <noscript>
            <img src="./assets/img/logo_email_header.png" alt="موثوق" id="logo"/>
          </noscript>
        </router-link>
        <div class="mh-top-navigation-wrapper">
          <div id="mh-top-navigation">
            <nav id="top-menu-nav" class="main_menu">
              <ul id="top-menu" class="nav">
                <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-10849">
                  <router-link :to="localizedRoute('home')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head"
                          :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">{{
                          $t('home')
                        }}</h3>
                    </div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-10849">
                  <router-link :to="localizedRoute('about')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('about') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-10801">
                  <router-link :to="localizedRoute('blogsPage')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('blog') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-10791">
                  <router-link :to="localizedRoute('faq')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('common_question') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-10801">
                  <router-link :to="localizedRoute('contact')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('contact') }}</h3>
                    </div>
                  </router-link>
                </li>

                <li v-if="!user"
                    class="menu-item menu-item-type-custom menu-item-object-custom mega-menu menu-item-17342">
                  <router-link :to="localizedRoute('register-as-checker')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('register_check') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li v-if="!user"
                    class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-16988">
                  <router-link :to="localizedRoute('register')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('register_user') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li v-if="!user"
                    class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-16988">
                  <router-link :to="localizedRoute('login')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('login') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li v-if="user" @click="dropdownOpened = !dropdownOpened"
                    class="nav-item dropdown show menu-item menu-item-type-custom menu-item-object-custom mhc-menu-highlight no-mega-menu">
                  <router-link to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                               class="nav-link dropdown-toggle mhc-menu-item-link-content">
                    <img src="assets/images/svg/logged-in-user.svg" alt="">
                    <span> {{ $t('hello') }} {{ isCheckerUser ? $t('eng') : '' }} {{ user.firstname }}
                      {{ user.lastname }}</span>
                    <img src="assets/images/svg/arrow-down.svg" alt=""></router-link>
                  <div aria-labelledby="user-action" v-if="dropdownOpened" class="dropdown-menu custom-menu show">
                    <router-link :to="localizedRoute('my-profile')" class="dropdown-item logout">
                      <img src="assets/images/svg/orders.svg" alt=""> {{ $t('my_profile') }}
                    </router-link>
                    <router-link :to="localizedRoute('projects')" class="dropdown-item logout" v-if="!isCheckerUser">
                      <img src="assets/images/svg/orders.svg" alt=""> {{ $t('my_projects') }}
                    </router-link>
                    <router-link :to="localizedRoute('checker-dashboard')" class="dropdown-item logout" v-if="isCheckerUser">
                      <img src="assets/images/svg/orders.svg" alt=""> {{ $t('checker_dashboard') }}
                    </router-link>
                    <!---->
                    <a class="dropdown-item logout logout-item" @click="logOut" style="cursor: pointer">
                      <img src="assets/images/svg/logout.svg" alt=""> {{ $t('logout') }}
                    </a>
                  </div>
                </li>
                <li v-if="(user) && !isCheckerUser"
                    class="menu-item menu-item-type-custom menu-item-object-custom mhc-menu-highlight no-mega-menu menu-item-16975"
                    :style='{
                      "min-height": "44px"
                    }'>
                  <router-link :to="localizedRoute('services')" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head">{{ $t('services') }}</h3>
                    </div>
                  </router-link>
                </li>
                <li class="menu-item mhc-menu-highlight" :style='{
                  "min-height": "44px"
                }'>
                  <div @click="changeLocal" class="menu-item-link">
                    <div class="mhc-menu-item-link-content">
                      <h3 class="mhc-menu-item-link-head text-white">{{ $local == 'ar' ? $t('en') : $t('ar') }}</h3>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <div class="mh-top-navigation-icons">
              <div class="mh-app-nav mh-app-nav-header app-nav mh_app_nav_side mh_nav_text_color_dark"
                   :class="{ 'mh-app-nav-opened': navOpend, 'mh-app-nav-animated': navOpend}">
                <div class="mh-app-nav-container">
                  <div class="app-menu">
                    <nav class="app-navigation">
                      <ul id="menu-mobile-menu" class="app-menu">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-15905">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('home')">
                            <div class="mhc-menu-item-link-content text-center">
                              <h3 class="mhc-menu-item-link-head">{{ $t('hello') }} {{ user.firstname }} {{
                                  user.lastname
                                }}
                              </h3>
                            </div>
                          </router-link>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-15905">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('home')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('home') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-15905">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('about')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('about') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-15906">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('faq')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('common_question') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu menu-item-15909">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('contact')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('contact') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="user && !isCheckerUser"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('projects')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('my_projects') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="user && isCheckerUser"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="navOpend = false" class="menu-item-link"
                                       :to="localizedRoute('checker-dashboard')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('checker_dashboard') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="user"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="logOut" class="menu-item-link">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('logout') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="!user"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="navOpend = false" class="menu-item-link"
                                       :to="localizedRoute('register-as-checker')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('register_as_checker') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="!user"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('register')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('register_user') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li v-if="!user"
                            class="menu-item menu-item-type-custom menu-item-object-custom no-mega-menu menu-item-17343">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('login')">>
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('login') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <li
                            class="menu-item menu-item-type-custom menu-item-object-custom mhc-menu-highlight no-mega-menu menu-item-16974">
                          <router-link @click="navOpend = false" class="menu-item-link" :to="localizedRoute('services')">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $t('services') }}</h3>
                            </div>
                          </router-link>
                        </li>
                        <!-- <li
                          class="menu-item menu-item-type-custom menu-item-object-custom mhc-menu-highlight no-mega-menu menu-item-16975">
                          <router-link @click="changeLocal" class="menu-item-link" to="">
                            <div class="mhc-menu-item-link-content">
                              <h3 class="mhc-menu-item-link-head">{{ $local == 'ar' ? $t('en') : $t('ar') }}</h3>
                            </div>
                          </router-link>
                        </li> -->
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="mobile-menu-trigger-icon">
                  <button class="hamburger hamburger--collapse is-active" type="button" aria-label="Menu"
                          @click="navOpend = !navOpend" aria-controls="app-navigation">
                    <span class="hamburger-box">
                      <span class="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
              </div>
              <div class="mobile-menu-trigger-icon">
                <button class="hamburger hamburger--collapse" type="button" @click="navOpend = !navOpend"
                        aria-label="Menu" aria-controls="app-navigation"> <span class="hamburger-box"> <span
                    class="hamburger-inner"></span> </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      dropdownOpened: false,
      navOpend: false,
    }
  },
  mounted() {
    document.addEventListener('click', event => {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpened = false;
      }
    });
  },
  computed: {
    user() {
      return this.$store.getters.user !== "null" && this.$store.getters.user != null ? this.$store.getters.user : false;
    },
    setting() {
      return this.$store.getters.setting !== "null" && this.$store.getters.setting != null ? this.$store.getters.setting : [];
    },
    isCheckerUser() {
      return this.$store.getters.isCheckerUser;
    },
    $local() {
      return this.$store.state.local; // Access locale from Vuex
    },
  },
  methods: {
    logOut() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      localStorage.removeItem('project')
      localStorage.removeItem('orderSummary')
      this.navOpend = false;
      window.location.replace('/')
    },
    changeLocal() {
      const newLocale = this.$local == 'ar' ? 'en' : 'ar';
      this.$store.dispatch('updateLocal', newLocale);
      document.documentElement.setAttribute('lang', newLocale);
    },
    localizedRoute($name) {
      return {
        name: $name,
        params: {locale: this.$i18n.locale=='ar'?'':this.$i18n.locale}
      }
    }
  },
  watch: {
    '$route'() {
      this.dropdownOpened = false;
    }
  }
}
</script>

<style scoped>
.dropdown-toggle::after {
  margin-top: 16px !important;
  margin: 0 4px;
}

.local-area {
  padding: 20px;
}

.activeLink {
  color: #9b4076 !important;
  border-bottom: 1px solid #9b4076;
}

.nav li {
  margin: 0;
  padding: 0 !important;
}

#mh-info a,
#top-header .mh-social-icon a,
#mh-info .mh-info-item {
  color: #FFFFFF !important;
}

.logo-img {
  width: 100%;
  height: 100%;
}

@media (max-width: 550px) {
  .logo-img {
    width: 3.5rem;
    height: auto;
  }
}
</style>
