<template>
  <footer id="main-footer">
    <div class="container">
      <div class="row">
        <div class="footer-widget col-md-4 col-sm-12">
          <div id="nav_menu-4" class="fwidget mhc_widget widget_nav_menu">
            <h4 class="title">{{ $t('explore_website') }}</h4>
            <div class="menu-primary-menu-container">
              <ul id="menu-primary-menu" class="menu">
                <li id="menu-item-10849" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10849">
                  <router-link :to="localizedRoute('about')">{{ $t('about') }}</router-link>
                </li>
                <li id="menu-item-10791" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10791">
                  <router-link :to="localizedRoute('faq')">{{ $t('common_question') }}</router-link>
                </li>
                <li id="menu-item-10801" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-10801">
                  <router-link :to="localizedRoute('contact')">{{ $t('contact') }}</router-link>
                </li>
                <li id="menu-item-17342" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-17342">
                  <router-link :to="localizedRoute('register-as-checker')">{{ $t('register_as_checker') }}</router-link>
                </li>
                <li id="menu-item-16988" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-16988">
                  <router-link :to="localizedRoute('login')">{{ $t('login') }}</router-link>
                </li>
                <li id="menu-item-16975" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-16975">
                  <router-link :to="localizedRoute('services')">{{ $t('request_service') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-widget  col-md-4 col-sm-12">
          <div id="text-3" class="fwidget mhc_widget widget_text">
            <h4 class="title">{{ $t('contact_and_question') }}</h4>
            <div class="textwidget">
              <p class="mb-0 pb-1">{{$t('footer.whatsapp')}}: <a
                  :href="'https://wa.me/' + setting.find(item => item.key == 'whatsapp')?.value" target="_blank">{{
                    setting.find(item => item.key == 'whatsapp')?.value }}</a><br />
                {{$t('footer.email')}}: <a :href="'mailto:' + setting.find(item => item.key == 'whatsapp')?.value" target="_blank"
                  rel="noopener">{{ setting.find(item => item.key == 'email')?.value }}</a>
              </p>
              <p v-if="setting.find(item => item.key == 'address')">{{ setting.find(item => item.key == 'address')?.value
              }}</p>
              <p v-else> {{$t("qatar")}}</p>

            </div>
          </div>
        </div>
        <div class="footer-widget  col-md-4 col-sm-12">
          <div id="custom_html-6" class="widget_text fwidget mhc_widget widget_custom_html">
            <h4 class="title">{{ $t('payment_methods') }}</h4>
            <div class="textwidget custom-html-widget">
              <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 320 56">
                <defs></defs>
                <title>Payment Logos</title>
                <path class="a"
                  d="M313,17.27c-.08.31-.15.62-.23.93a6,6,0,0,1-6.24,4.57,11.94,11.94,0,0,1-3.5-.58,4,4,0,0,0-1.31.08c-.58.05-1.16.15-1.74.15-5.45,0-10.91,0-16.37,0H283l.39-2.57h6.86c-.05.05,0,0,0,0,0-1.68-.05-3.35-.1-5a2.9,2.9,0,0,0-.27-.93,2.41,2.41,0,0,0-2.4-1.61H284.2c.12-.84.23-1.62.36-2.39a.36.36,0,0,1,.26-.23,39.24,39.24,0,0,1,4.61.15,5.19,5.19,0,0,1,4.3,4.55,20.6,20.6,0,0,1,.19,2.72c0,.87,0,1.75,0,2.66l.43,0c2,0,3.94,0,5.91,0a.51.51,0,0,0,.61-.52,5.94,5.94,0,0,0,0-.73V13.59A3.36,3.36,0,0,1,303.54,10c.72-.22,1.49-.33,2.24-.48h1.58a3.58,3.58,0,0,0,.35.09,5.78,5.78,0,0,1,5,4.39c.11.38.19.77.28,1.16Zm-8.7-4.21c0,.48.09.89.1,1.3,0,1.55,0,3.11,0,4.66,0,.38.13.53.48.55.62,0,1.23.12,1.84.13a2.38,2.38,0,0,0,2.56-1.88,6.57,6.57,0,0,0,0-3.52A2,2,0,0,0,308,12.77,5.29,5.29,0,0,0,304.3,13.06Z" />
                <path class="a"
                  d="M281.72,9.72l-.39,2.6h-7.5a7.87,7.87,0,0,0-1,0,1.45,1.45,0,0,0-1.4,1.63A1.48,1.48,0,0,0,273,15.52c1.17,0,2.35,0,3.52,0a9.06,9.06,0,0,1,1.56.15,3.85,3.85,0,0,1,3.14,4,7,7,0,0,1-.18,1.51A3.53,3.53,0,0,1,278,23.81a12.29,12.29,0,0,1-2.34.23c-5.14,0-10.27,0-15.4,0a5.28,5.28,0,0,1-5.51-5.3A13.9,13.9,0,0,1,255,14a5.44,5.44,0,0,1,5.36-4.15c1.62,0,3.24,0,4.89,0l-.39,2.64H263c-.6,0-1.2,0-1.8,0a2.45,2.45,0,0,0-2.31,1.57,5.25,5.25,0,0,0-.41,1.78,18.82,18.82,0,0,0,.12,3.36A2.36,2.36,0,0,0,261,21.35c1.5.09,3,.06,4.53.07h9.94a3.64,3.64,0,0,0,.74-.05,1.77,1.77,0,0,0-.31-3.51c-1.45,0-2.9,0-4.35-.07a3.54,3.54,0,0,1-3.4-2.52,4.11,4.11,0,0,1,1.19-4.46,5.52,5.52,0,0,1,3.37-1.11c3-.05,5.92,0,8.88,0A1,1,0,0,1,281.72,9.72Z" />
                <path class="a"
                  d="M313,46.5c-1.15-.2-2.3-.43-3.45-.61a3.11,3.11,0,0,0-1,.09,9.5,9.5,0,0,1-4.21.06,3.33,3.33,0,0,1-2.68-3.32,3.2,3.2,0,0,1,2.46-3.51,17.36,17.36,0,0,1,3.14-.46c.71-.06,1.44,0,2.15,0,.35-1.46-.33-2.38-1.78-2.41a13.73,13.73,0,0,0-4.66.86l-.48.15-.38-2.6c2.49-.8,5-1.52,7.61-1a3.65,3.65,0,0,1,3.2,3.43,2.33,2.33,0,0,0,.08.36Zm-4.86-5.64v0a16.16,16.16,0,0,0-2,.29,1.34,1.34,0,0,0-.91,1.54,1.19,1.19,0,0,0,1.09,1.18,7.75,7.75,0,0,0,2.09-.11c1.14-.2,1.14-.24,1.13-1.42,0-.45.11-1-.12-1.32S308.58,40.89,308.14,40.86Z" />
                <path class="a"
                  d="M272.53,46.1H268.9V38.59a6.19,6.19,0,0,0,0-.74,1.32,1.32,0,0,0-1.22-1.24,4,4,0,0,0-2.28.39c0,1,.06,2,.06,2.94,0,1.88,0,3.76,0,5.64v.52h-3.62v-.53c0-2.31,0-4.63,0-6.94,0-1.8-.78-2.27-2.47-2-1,.18-1,.2-1,1.21v8.21h-3.69V36.55c0-1.51.39-2,1.86-2.43a11.91,11.91,0,0,1,5.53-.37,6.16,6.16,0,0,1,1.46.55.67.67,0,0,0,.67.05,8.63,8.63,0,0,1,5.51-.52,3.38,3.38,0,0,1,2.77,3.28c.08,2.37.05,4.75.07,7.12Z" />
                <path class="a"
                  d="M295.94,34.11v-.54c0-1.51,0-3,0-4.53,0-.24,0-.4.32-.45L299.6,28V43.44c0,1.42-.31,1.85-1.66,2.27a12.2,12.2,0,0,1-5.4.4,5.31,5.31,0,0,1-4.6-5.13,10.56,10.56,0,0,1,.26-3.54,4.71,4.71,0,0,1,4.69-3.77,17.18,17.18,0,0,1,2.46.29A5.12,5.12,0,0,1,295.94,34.11Zm0,5.92v-1.8c0-1.66,0-1.66-1.67-1.8a2.22,2.22,0,0,0-2.55,1.76,6.59,6.59,0,0,0,0,3.47,2.36,2.36,0,0,0,2.54,1.89c.4,0,.8-.09,1.19-.12s.52-.19.5-.58C295.92,41.91,295.94,41,295.94,40Z" />
                <path class="a"
                  d="M282.37,38.75c.11-1.86-.46-2.46-2.22-2.42a14.27,14.27,0,0,0-4.24.85l-.51.16c-.11-.83-.23-1.63-.33-2.43a.32.32,0,0,1,.19-.25,17.17,17.17,0,0,1,6.1-1.08,7.77,7.77,0,0,1,1,.1,3.77,3.77,0,0,1,3.53,4.16c0,2.77,0,5.55,0,8.33,0,.09,0,.18,0,.32-.41-.07-.8-.11-1.18-.2a9.47,9.47,0,0,0-4.46-.13,6.16,6.16,0,0,1-3.29-.22,3.58,3.58,0,0,1-.23-6.64,8.48,8.48,0,0,1,3.12-.55C280.65,38.73,281.49,38.75,282.37,38.75Zm.07,2.21c-1.06,0-2-.07-3,0a1.44,1.44,0,0,0-1.33,1.6,1.35,1.35,0,0,0,1.48,1.33,21.51,21.51,0,0,0,2.63-.43.34.34,0,0,0,.2-.25C282.45,42.47,282.44,41.72,282.44,41Z" />
                <rect class="b" x="202.06" y="9.5" width="46.91" height="15.54" />
                <rect class="c" x="202.06" y="30.68" width="46.91" height="15.54" />
                <path class="a"
                  d="M18.27,51V47.94A1.8,1.8,0,0,0,16.33,46a2,2,0,0,0-1.74.87A1.8,1.8,0,0,0,13,46a1.65,1.65,0,0,0-1.43.71V46.1H10.45V51h1.07V48.3c0-.87.46-1.28,1.18-1.28s1.07.46,1.07,1.28V51h1.07V48.3A1.14,1.14,0,0,1,16,47c.72,0,1.07.46,1.07,1.28V51Zm15.9-4.91H32.43V44.62H31.36V46.1h-1v1h1v2.25c0,1.13.46,1.79,1.69,1.79a2.81,2.81,0,0,0,1.33-.36l-.31-.92a1.64,1.64,0,0,1-.92.26c-.51,0-.72-.31-.72-.82v-2.2h1.74v-1Zm9.1-.1a1.44,1.44,0,0,0-1.27.71V46.1H40.92V51H42V48.25c0-.82.35-1.28,1-1.28a3,3,0,0,1,.66.1l.31-1a3.44,3.44,0,0,0-.72,0Zm-13.75.51a3.49,3.49,0,0,0-2-.51c-1.23,0-2.05.61-2.05,1.58s.61,1.28,1.69,1.44l.51,0c.56.1.87.25.87.51s-.41.61-1.13.61a2.77,2.77,0,0,1-1.63-.51l-.51.82a3.57,3.57,0,0,0,2.09.61c1.43,0,2.25-.66,2.25-1.58S29,48.2,27.93,48l-.51,0c-.46-.05-.82-.15-.82-.46S27,47,27.53,47a3.75,3.75,0,0,1,1.53.41l.46-.87ZM58.05,46a1.47,1.47,0,0,0-1.28.71V46.1H55.7V51h1.07V48.25c0-.82.36-1.28,1-1.28a3,3,0,0,1,.66.1l.31-1a3.49,3.49,0,0,0-.72,0Zm-13.7,2.56A2.45,2.45,0,0,0,47,51.11a2.52,2.52,0,0,0,1.73-.56l-.51-.87a2.05,2.05,0,0,1-1.28.46,1.45,1.45,0,0,1-1.48-1.58A1.52,1.52,0,0,1,46.9,47a2.05,2.05,0,0,1,1.28.46l.51-.87A2.52,2.52,0,0,0,47,46a2.45,2.45,0,0,0-2.61,2.56Zm9.92,0V46.1H53.19v.61A1.83,1.83,0,0,0,51.66,46a2.56,2.56,0,0,0,0,5.11,1.7,1.7,0,0,0,1.53-.71V51h1.08Zm-3.94,0a1.49,1.49,0,1,1,3,0,1.49,1.49,0,1,1-3,0ZM37.5,46a2.56,2.56,0,0,0,0,5.11,3.12,3.12,0,0,0,2-.66L39,49.68a2.42,2.42,0,0,1-1.43.51A1.37,1.37,0,0,1,36.12,49h3.63v-.41A2.26,2.26,0,0,0,37.5,46Zm0,.92a1.19,1.19,0,0,1,1.22,1.18H36.17a1.29,1.29,0,0,1,1.33-1.18Zm26.64,1.64v-4.4H63.06v2.55A1.84,1.84,0,0,0,61.53,46a2.56,2.56,0,0,0,0,5.11,1.71,1.71,0,0,0,1.53-.71V51h1.08Zm-3.94,0a1.49,1.49,0,1,1,3,0,1.49,1.49,0,1,1-3,0Zm-35.9,0V46.1H23.23v.61A1.84,1.84,0,0,0,21.7,46a2.56,2.56,0,0,0,0,5.11,1.72,1.72,0,0,0,1.53-.71V51H24.3Zm-4,0a1.48,1.48,0,1,1,3,0,1.48,1.48,0,1,1-3,0Z" />
                <rect class="d" x="29.16" y="8.83" width="16.11" height="28.94" />
                <path class="e"
                  d="M30.18,23.3a18.47,18.47,0,0,1,7-14.47,18.4,18.4,0,1,0,0,28.94A18.41,18.41,0,0,1,30.18,23.3Z" />
                <path class="f"
                  d="M67,23.3A18.4,18.4,0,0,1,37.24,37.77a18.44,18.44,0,0,0,0-28.94A18.39,18.39,0,0,1,67,23.3Z" />
                <polygon class="g" points="128.28 41.49 121.26 41.49 125.65 14.53 132.67 14.53 128.28 41.49" />
                <path class="g"
                  d="M115.36,14.53l-6.69,18.54-.79-4h0L105.52,17s-.29-2.43-3.33-2.43H91.13L91,15a26.15,26.15,0,0,1,7.34,3.08l6.1,23.42h7.31l11.16-27Z" />
                <path class="g"
                  d="M170.56,41.49H177l-5.62-27h-5.64a3.22,3.22,0,0,0-3.24,2L152,41.49h7.31l1.46-4h8.92ZM162.83,32l3.69-10.09L168.6,32Z" />
                <path class="g"
                  d="M152.58,21l1-5.79a20.43,20.43,0,0,0-6.32-1.17c-3.48,0-11.75,1.52-11.75,8.92,0,7,9.71,7.05,9.71,10.71s-8.71,3-11.58.7l-1,6A19.67,19.67,0,0,0,140.53,42c4.78,0,12-2.48,12-9.23,0-7-9.79-7.66-9.79-10.7S149.58,19.36,152.58,21Z" />
                <path class="h"
                  d="M107.88,29.08,105.52,17s-.29-2.43-3.33-2.43H91.13L91,15a27.17,27.17,0,0,1,10.42,5.23A21,21,0,0,1,107.88,29.08Z" />
              </svg>
              <div style="margin-top:25px; margin-left:15px; display: inline-block;"><a
                  href="./assets/img/%d8%b4%d9%87%d8%a7%d8%af%d8%a9-%d8%aa%d8%b3%d8%ac%d9%8a%d9%84-%d8%ac%d8%af%d9%8a%d8%af-%d9%81%d9%8a-%d8%b6%d8%b1%d9%8a%d8%a8%d8%a9-%d8%a7%d9%84%d9%82%d9%8a%d9%85%d8%a9-%d8%a7%d9%84%d9%85%d8%b6%d8%a7%d9%81%d8%a9.pdf"
                  target="_blank" rel="noopener"><img alt="VAT" width="80" height="auto" data-src="/./assets/img/vat.png"
                    class="lazyload" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==">
                  <noscript><img src="./assets/img/vat.png" alt="VAT" width="80" height="auto"></noscript>
                </a></div>
              <div style="margin-top:25px; display: inline-block; float:left;"><a href="https://maroof.sa/88845"
                  target="_blank" rel="noopener"><img alt="Maroof" width="200" height="auto"
                    data-src="/./assets/img/ImageCr.png" class="lazyload"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==">
                  <noscript><img src="./assets/img/ImageCr.png" alt="Maroof" width="200" height="auto"></noscript>
                </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-bottom">
      <div class="container clearfix">
        <div class="social-icons-wrapper">
          <div class="container clearfix">
            <div id="mh-info" v-if="setting.find(item => item.key == 'whatsapp')?.value">
              <span class="mh-info-item mh-info-phone mh-icon-before mh-icon-after"><a
                  :href="'https://wa.me/' + setting.find(item => item.key == 'whatsapp')?.value" target="_blank">
                  {{ setting.find(item => item.key == 'whatsapp')?.value }}</a></span>
            </div>
            <ul class="mh-social-icons">
              <li class="mh-social-icon mh-social-twitter"
                v-if="setting.find(item => item.key == 'twitter')?.value || false">
                <a target="_blank" :href="setting.find(item => item.key == 'twitter')?.value" class="icon">
                  <span>Twitter</span>
                </a>
              </li>
              <li class="mh-social-icon mh-social-facebook"
                v-if="setting.find(item => item.key == 'facebook')?.value || false">
                <a target="_blank" :href="setting.find(item => item.key == 'facebook')?.value" class="icon">
                  <span>Facebook</span>
                </a>
              </li>
              <li class="mh-social-icon mh-social-instagram"
                v-if="setting.find(item => item.key == 'instagram')?.value || false">
                <a target="_blank" :href="setting.find(item => item.key == 'instagram')?.value"
                  class="icon"><span>Instagram</span>
                </a>
              </li>
              <li class="mh-social-icon mh-social-linkedin"
                v-if="setting.find(item => item.key == 'linkedin')?.value || false">
                <a target="_blank" :href="setting.find(item => item.key == 'linkedin')?.value"
                  class="icon"><span>linkedin</span>
                </a>
              </li>
            </ul>
            <div id="mh-secondary-menu"></div>
          </div>
        </div>
        <div id="footer-info">
          <div class="mh-copyrights"> {{ $t('policy_1') }}
            <router-link :to="localizedRoute('termsConditions')" class="mx-2">
              {{ $t('policy_2') }}
            </router-link>
            <router-link :to="localizedRoute('privacyPolicy')" class="mx-2">
              {{ $t('policy_3') }}
            </router-link>
            <router-link :to="localizedRoute('returnsPolicy')" class="mx-2">
              {{ $t('policy_4') }}
            </router-link>
            <!-- <a href="policy-of-use/index.html">{{ $t('policy_2') }}</a> |
            <a href="privacy-policy/index.html"> {{ $t('policy_3') }}</a> |

            <a href="refund-policy/index.html">{{ $t('policy_4') }}</a> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  computed: {
    setting() {
      return this.$store.getters.setting !== "null" && this.$store.getters.setting != null ? this.$store.getters.setting : [];
    }
  },
  methods:{
    localizedRoute($name) {
      return {
        name: $name,
        params: {locale: this.$i18n.locale=='ar'?'':this.$i18n.locale}
      }
    }
  }
}
</script>

<style scoped>
.activeLink {
  color: #9b4076 !important;
  border-bottom: 1px solid #9b4076;
}
</style>


